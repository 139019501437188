<template>
    <div class="my_treat_page">
        <div class="name_info">
            <div class="name_num">
                <span class="left">{{detailinfo.name}}</span>
            </div>
            <div class="account_info">
                <div class="sex_age_phone">
                    <span>{{detailinfo.sex}} . {{detailinfo.age}}岁</span>
                    <span v-if="detailinfo.phone">{{detailinfo.phone}}</span>
                </div>
                <div class="account">
                    <span>工号：{{dispname}}</span>
                </div>
            </div>

        </div>
        <div class="habitus_info">
            <div class="info_line">
                <span>执行医生</span>
                <p class="context_p">{{detailinfo.execdoctor  || '--'}}</p>
            </div>
            <div class="info_line">
                <span>执行时间</span>
                <p class="context_p">{{detailinfo.exectime}}</p>
            </div>
            <div class="info_line">
                <span>报告医生</span>
                <p class="context_p">{{detailinfo.doctor  || '--'}}</p>
            </div>
            <div class="info_line">
                <span>报告时间</span>
                <p class="context_p">{{detailinfo.reporttime}}</p>
            </div>
            <div class="info_line">
                <span>先天体质</span>
                <div class="desc_p">
                    <p class="val">{{detailinfo.xtval}}</p>
                    <p class="desc">{{detailinfo.xtdesc}}</p>
                </div>
            </div>
            <div class="info_line">
                <span>后天体质</span>
                <div class="desc_p">
                    <p class="val">{{detailinfo.htval}}</p>
                    <p class="desc">{{detailinfo.htdesc}}</p>
                </div>
            </div>
            <div class="info_line">
                <span>节令体质</span>
                <div class="desc_p">
                    <p class="val">{{detailinfo.jlval}}</p>
                    <p class="desc">{{detailinfo.jldesc}}</p>
                </div>
            </div>
            <div class="info_line">
                <span>体质描述</span>
                <p class="context">{{detailinfo.habtiusdesc || '--'}}</p>
            </div>
            <div class="info_line">
                <span>辨析建议</span>
                <p class="context">{{detailinfo.analysissuggested || '--'}}</p>
            </div>
            <div class="info_line">
                <span>养生保健</span>
                <p class="context">{{detailinfo.healthcare || '--'}}</p>
            </div>
        </div>

        <div class="jl_list" v-if="detailinfo.jllist && detailinfo.jllist.length > 0">
            <div class="jl_th">
                <div>时间</div>
                <div>模型</div>
            </div>
            <div class="one" v-for="item in detailinfo.jllist" :key="item.id">
                <div class="jl_td">
                    <div>{{item.startdate}}--{{item.enddate}}</div>
                    <div>
                        <p>{{item.val}}</p>
                        <p>{{item.desc}}</p>
                    </div>
                </div>
            </div>
        </div>

         <div class="ctrl_bottom">
             <button @click="back">返回</button>
             <button class="exec_class" @click="download(detailinfo.reporturl)" v-if="detailinfo.reporturl" >下载报告</button>
        </div>
        
    </div>
</template>

<script>
    import { wxhabitusreportqry } from '@/api/medical'
    import { mapGetters } from 'vuex'
    import { Toast } from 'vant'
    export default {
        computed: {
            ...mapGetters([
                'username','dispname'
            ]),
        },
        data() {
            return {
                serviceid: '',
                paystatus: '',
                detailinfo: {}
            }
        },
        mounted () {
            this.$store.dispatch('hideOrShowNav', false)
            this.serviceid = this.$route.query.serviceid || ''
            this.paystatus = this.$route.query.paystatus || ''
            this.getDetailInfo()
        },
        methods: {
            // 获取订单信息
            getDetailInfo(){
                Toast.loading({
                    message: '加载中...',
                    forbidClick: true,
                })
                let data = {
                    username: this.username,
                    serviceid: this.serviceid,
                    paystatus: this.paystatus
                }
                wxhabitusreportqry(data).then(response => {
                    Toast.clear()
                    this.detailinfo = response.response_body
                })
            },
            //返回
            back() {
                this.$router.go(-1)
            },
            //下载报告
            download(url) {
                window.open(url, "_blank");
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>